import {
  DepositStatuses,
  WithdrawalStatuses,
} from '../../apis/transfers/types';
import { TypographyProps } from '@mui/material';

export const withdrawalStatusColorMap: Record<
  WithdrawalStatuses,
  TypographyProps['color']
> = {
  [WithdrawalStatuses.STATUS_PENDING]: 'yellow',
  [WithdrawalStatuses.STATUS_REVIEWING]: 'yellow',
  [WithdrawalStatuses.STATUS_PENDING_TO_APPROVE]: 'yellow',
  [WithdrawalStatuses.STATUS_REJECTED]: 'red',
  [WithdrawalStatuses.STATUS_ERROR]: 'red',
  [WithdrawalStatuses.STATUS_APPROVED]: 'green',
  [WithdrawalStatuses.STATUS_CLOSED]: 'green',
  [WithdrawalStatuses.STATUS_SENT]: 'green',
  [WithdrawalStatuses.STATUS_TRANSFER]: 'green',
};

export const depositStatusColorMap: Record<
  DepositStatuses,
  TypographyProps['color']
> = {
  [DepositStatuses.STATUS_PENDING]: 'inherit',
  [DepositStatuses.STATUS_IN_REVIEW]: 'yellow',
  [DepositStatuses.STATUS_BLOCKED_BY_RISK]: 'red',
  [DepositStatuses.STATUS_BLOCKED_BY_LIMIT]: 'red',
  [DepositStatuses.STATUS_BLOCKED_BY_ERROR]: 'red',
  [DepositStatuses.STATUS_COMPLETED]: 'green',
  [DepositStatuses.STATUS_INCOMPLETE]: 'yellow',
};
